//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FightCampLogo from '@/shared/components/FightCampLogo.vue';
import { windowMatchMediaMixin } from '~/mixins/matchMedia';
import EmailBrochureForm from './EmailBrochureForm.vue';
import DownloadBrochureForm from './DownloadBrochureForm.vue';

export default {
  name: 'TopFooterForm',
  mixins: [windowMatchMediaMixin],
  components: {
    FightCampLogo,
    EmailBrochureForm,
    DownloadBrochureForm,
  },
  data() {
    return {
      isNotSubmitted: true,
    };
  },
  computed: {
    view_name() {
      return `${this.$route.name}_${this.$options.name}`;
    },
    fcLogoWidth() {
      return this.mixinMatchMedia.isTabletUp ? '119px' : '84px';
    },
    fcLogoHeight() {
      return this.mixinMatchMedia.isTabletUp ? '89px' : '67px';
    },
  },
  methods: {
    getLinkName(name) {
      return `${this.view_name}_${name}`;
    },
    handleSubmission() {
      this.isNotSubmitted = !this.isNotSubmitted;
    },
  },
};
