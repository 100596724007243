//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ButtonLink from '@/shared/components/ButtonLink.vue';

export default {
  name: 'DownloadBrochureForm',
  components: {
    ButtonLink,
  },
  computed: {
    brochureDownloadOptions() {
      return {
        to: '/getbrochure',
        trackClick: 'DownloadBrochure_FooterButton',
      };
    },
  },
};
