//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { red, bluetint } from '@fc/angie-ui/dist/styles/_js_exports.scss';

export default {
  name: 'FightCampLogo',
  props: {
    width: {
      type: String,
      default: '80px',
    },
    white: Boolean,
    gray: Boolean,
    red: Boolean,
    blue: Boolean,
    black: Boolean,
  },
  computed: {
    color() {
      if (this.red) { return red; }
      if (this.white) { return '#FFFFFF'; }
      if (this.gray) { return '#434343'; }
      if (this.blue) { return bluetint; }
      if (this.black) { return '#000000'; }
      return '#212121';
    },

  },
};
